import axios from "axios";
import { Notif } from "../_components";
import { authHeader, downloadXLSX } from "../_helpers";

const { REACT_APP_REPORT_URL, REACT_APP_REPORT_RENDER_URL } = process.env;

const URL = {
  LO_EXCEL: {
    REPORT: "/api/reports/lo",
    RENDER: "/api/new_reports/lo_excel",
  },
};

const token = authHeader();

const reportClient = axios.create({
  baseURL: REACT_APP_REPORT_URL,
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
    Authorization: token,
  },
});

const renderClient = axios.create({
  baseURL: REACT_APP_REPORT_RENDER_URL,
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
    Authorization: token,
  },
});

export async function downloadExcelFile(query, setSubmitting) {
  try {
    const response = await reportClient.get(URL.LO_EXCEL.REPORT, {
      params: query,
    }); 
    const data = response?.data;
    const dataRender = await renderClient.post(URL.LO_EXCEL.RENDER, data);
    return downloadXLSX(dataRender.data, 'Laporan Operasional.xlsx')
  } catch (error) {
    Notif("Opssss", error?.message, "toast-bottom-right", "4");
  } finally {
    setSubmitting(false);
  }
}
