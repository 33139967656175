
 
export {dashboard_filter,
        item_filter,
        paket_filter, 
        purchase_filter,
        sale_filter,
        opname_filter,
        mutation_filter,
        stock_out_filter,
        production_filter,
        jurnal_filter
      }

function dashboard_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'DASHBOARD_FILTER':
      return action
    default:
      return state 
  }
}

function item_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'ITEM_FILTER':
      return action
    default:
      return state 
  }
}

function paket_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'PAKET_FILTER':
      return action
    default:
      return state 
  }
}

function purchase_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'PURCHASE_FILTER':
      return action
    default:
      return state 
  }
}

function sale_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'SALE_FILTER':
      return action
    default:
      return state 
  }
}

function opname_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'OPNAME_FILTER':
      return action
    default:
      return state 
  }
}

function mutation_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'MUTATION_FILTER':
      return action
    default:
      return state 
  }
}

function stock_out_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'STOCK_OUT_FILTER':
      return action
    default:
      return state 
  }
}

function production_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'PRODUCTION_FILTER':
      return action
    default:
      return state 
  }
}

function jurnal_filter(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'JURNAL_FILTER':
      return action
    default:
      return state 
  }
}
 
