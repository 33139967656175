import $ from 'jquery';

export function correctHeight() {
  const pageWrapper = $('#page-wrapper');
  const navbarHeight = $('nav.navbar-default').height();
  const wrapperHeigh = pageWrapper.height();

  if (navbarHeight > wrapperHeigh) {
    pageWrapper.css('min-height', navbarHeight + 'px');
  }

  if (navbarHeight <= wrapperHeigh) {
    if (navbarHeight < $(window).height()) {
      pageWrapper.css('min-height', $(window).height() + 'px');
    }
    else {
      pageWrapper.css('min-height', navbarHeight + 'px');
    }
  }

  if ($('body').hasClass('fixed-nav')) {
    if (navbarHeight > wrapperHeigh) {
      pageWrapper.css('min-height', navbarHeight + 'px');
    }
    else {
      pageWrapper.css('min-height', $(window).height() - 60 + 'px');
    }
  }
}

export function detectBody() {
  if ($(document).width() < 769) {
    $('body').addClass('body-small');
  }
  else {
    $('body').removeClass('body-small');
  }
}

export function smoothlyMenu() {
  if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
    // Hide menu in order to smoothly turn on when maximize menu
    $('#side-menu').hide();
    // For smoothly turn on menu
    setTimeout(
      function() { // eslint-disable-line func-names
        $('#side-menu').fadeIn(400);
      }, 200);
  }
  else if ($('body').hasClass('fixed-sidebar')) {
    $('#side-menu').hide();
    setTimeout(
      function() { // eslint-disable-line func-names
        $('#side-menu').fadeIn(400);
      }, 100);
  }
  else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    $('#side-menu').removeAttr('style');
  }
}

export function downloadXLSX(base64String, filename) {
  const base64Data = base64String.replace(
    /^data:application\/vnd.openxmlformats-officedocument\.spreadsheetml\.sheet;base64,/,
    ""
  );

  const binaryData = atob(base64Data);

  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}
