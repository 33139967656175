import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive';
import $ from 'jquery';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDOM from 'react-dom';
import { Loading } from '../../_components';
import { authHeader } from '../../_helpers';

const { REACT_APP_API_URL :  API_URL} =process.env

class ItemsModalRedux extends React.Component {

  constructor({props}) {
      super(props);
      this.state = {
        loading : null,
    }
  }


  componentDidMount(){
       $('#items').DataTable( {
        ordering: true,
        searching: true,
        processing: true,
        serverSide:true,
        bFilter: true,
        bInfo:true,
        bLengthChange: true,
        bPaginate:true,
        scrollCollapse: true,
        scrollY: true,
        scrollX: true,
        "ajax": {
          "url": `${API_URL}/api/items_modal${this.props.modal.query ? this.props.modal.query : ''}`,
          "dataSrc" : 'data',
          "beforeSend": function (xhr) {
              xhr.setRequestHeader('Authorization',authHeader())
            }
          },
        columnDefs : [
            {
                'targets': [0],
                createdCell : (td, cellData, rowData, row, col) => {
                    const key = String(`${rowData?.[0]}:${rowData[1]} - ${rowData[2]}`);
                    ReactDOM.render(<React.Fragment>
                    <button 
                      {... (this.props.modal.banned_option && { disabled : (
                                                                            this.props.modal.banned_option
                                                                            .find(e => e === (rowData[10] ===6 ? `p-${rowData[0]}` : rowData[0])) 
                                                                            || (rowData[7] === 0 
                                                                            && (rowData[10] === 1 
                                                                            ||  rowData[10] === 2)  
                                                                          ) ? true : false )})}
                      {... (this.props.modal.banned_codition && {
                        disabled : (this.props.modal.banned_codition.find(e => key === String(e) || key.startsWith(`${e}:`) ))})
                      }
                      className="btn btn-primary btn-sm" 
                      onClick={() => {
                        this.Pilih(
                            rowData[0],
                            `${rowData[1]} - ${rowData[2]}`, 
                            rowData[8], 
                            rowData[7],
                            rowData[10],
                            rowData[4],
                            rowData[11],
                            rowData[12],rowData[13],rowData[14])
                      }}>Pilih</button>
                      </React.Fragment>,td);
                }
            },{
                'targets': [7],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment>{ new Intl.NumberFormat( 'ID' ).format((Math.round(rowData[7] * 100) / 100).toFixed(2))}</React.Fragment>,td);
                }
            },{
                'targets': [8],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment><b>{new Intl.NumberFormat( 'ID' ).format(rowData[8])}</b></React.Fragment>,td);
                }
            }
            
        ]
     } );
    }

   Pilih(id,nm, price, this_qty, type_item, unit, id_unit, hpp, id_acc_hpp, acc_hpp){
     this.setState({loading: true})
     const { name, name_price, name_id, onClick, onChange, available_qty}= this.props.modal

     name && onClick(name, nm)
     name_id && onClick(name_id, id)

     available_qty && onClick(available_qty, this_qty)
     
     name_price && onClick(name_price, price)
     
    
     onChange && onChange(String(price),type_item, unit, id_unit, this_qty, hpp, id, nm, id_acc_hpp, acc_hpp)
     
     this.setState({loading: false})
     this.onClose()
   } 

   onClose = () =>{
    this.props.Modal(null)
   }

  render() {

    const backdropStyle = {
      position: 'fixed', /* Stay in place */
      zIndex: 1, /* Sit on top */
      left: 0,
      top: 0,
      width: '100%', /* Full width */
      height: '100%', /* Full height */
      overflow: 'auto', /* Enable scroll if needed */
      backgroundColor: 'rgb(0,0,0)', /* Fallback color */
      backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
    };

   
    return (
      <div className="modal-backdrop" style={backdropStyle}>
      <div className='animated fadeInRight'>
        <Modal.Dialog 
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            style={{top : 25}}
         >
         <Modal.Header>
        <Modal.Title>Cari Items</Modal.Title>
        </Modal.Header>

        {this.state.loading ? <div><Loading/></div> :
          <Modal.Body>
                  <table className="table table-striped" style={{whiteSpace: 'nowrap', width: '100%'}} id="items">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Kode</th>
                            <th>Nama</th>
                            <th>Type</th>
                            <th>Satuan</th>
                            <th>Part Number</th>
                            <th>Merek</th>
                            <th>Stok Akhir</th>
                            <th>Harga</th>
                            <th>Note</th>
                        </tr> 
                    </thead>
                  </table>
          </Modal.Body>
        }
        <Modal.Footer>
            <Button variant="secondary" onClick={this.onClose}>Close</Button>
        </Modal.Footer>
        </Modal.Dialog>
      </div>
      </div>
    );
  }
}


export { ItemsModalRedux };




