import React from "react";
import { ErrorMessage } from "formik";
import AsyncSelect from "react-select/async";
import { Notif } from "../_components";
import axios from "axios";
import { axiosHeader } from "../_helpers";
import { authenticationService } from "../_services";

const { REACT_APP_API_URL: API_URL } = process.env;

class SelectBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      axiosHeader: axiosHeader(),
      user: authenticationService.currentUserValue,
      cabang: null,
    };
  }

  componentDidMount() {
    axios
      .all([
        axios.get(
          `${API_URL}/api/getcabang/?id=${this.state.user.id_cabang}`,
          this.state.axiosHeader
        ),
      ])
      .then(
        axios.spread((cabang) => {
          this.setState({
            cabang: cabang.data,
          });
          this.props.setValue &&
            this.props.setValue(
              this.state.user.id_cabang,
              `( ${cabang.data[0].code} ) ${cabang.data[0].name}`
            );
        })
      )
      .catch(
        ({
          response: {
            request: { response: error },
          },
        }) => {
          Notif("Opssss", error, "toast-bottom-right", "4");
        }
      );
  }

  loadOptions = (inputValue, is_main) => {
    return new Promise((resolve) => {
      if (inputValue.length > 2 && is_main) {
        axios
          .all([
            axios.get(
              `${API_URL}${
                this.props.url
              }?name=${inputValue}&code=${inputValue}${
                this.props.id_regional
                  ? `&id_regional=${this.props.id_regional}`
                  : ""
              }`,
              this.state.axiosHeader
            ),
          ])
          .then(
            axios.spread((params) => {
              resolve(params.data.slice(0, this.props.view));
            })
          )
          .catch(
            ({
              response: {
                request: { response: error },
              },
            }) => {
              Notif("Opssss", error, "toast-bottom-right", "4");
              resolve(null);
            }
          );
      } else {
        if (is_main) {
          resolve(this.props.options);
        } else {
          resolve([]);
        }
      }
    });
  };

  render() {
    if (this.state.cabang && this.state.cabang.length) {
      const { user_branches } = this.state.user || {};
      const [{ code: code_branch, name: name_branch, is_main, id: id_branch }] =
        this.state.cabang;
      const {
        errors,
        touched,
        name,
        select_ref,
        handleSelect,
        view,
        options,
        defaultValue,
        disabled,
      } = this.props;

      const valueDefault = [];
      let optionsDefault = [];

      if (!is_main) {
        optionsDefault = user_branches;
      } else {
        optionsDefault = options.slice(0, view);
      }

      valueDefault.push({
        label: `( ${code_branch} ) ${name_branch}`,
        value: id_branch,
      });

      return (
        <React.Fragment>
          <AsyncSelect
            ref={(ref) => {
              if (select_ref) {
                select_ref(ref);
              }
            }}
            name={name}
            isDisabled={disabled ? disabled : false}
            defaultOptions={optionsDefault}
            className={
              "basic-multi-select" + (errors && touched ? " is-invalid" : "")
            }
            classNamePrefix="select"
            loadOptions={(inputValue) => this.loadOptions(inputValue, is_main)}
            defaultValue={defaultValue ? defaultValue : valueDefault}
            onChange={(selectChoice, { name }) => {
              handleSelect(selectChoice, { name });
            }}
          />
          <ErrorMessage
            name={name}
            component="div"
            style={{ color: "red" }}
            className="invalid-feedback"
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export { SelectBranch };
