import React, { lazy }from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { authenticationService } from '../_services';
import { PrivateRoute,Navigation, Progress, TopHeader, Footer, WaitingComponent} from '../_components';
import '../assets/dependencies';
import $ from 'jquery';
import { correctHeight, detectBody, history } from '../_helpers';

const Login = lazy(() => import('../login/login'));
const Home = lazy(() => import('../home/home'));

const Regional = lazy(() => import('../setting/regionals/regionals'));
const AddRegional = lazy(() => import('../setting/regionals/regional_add'));
const EditRegional = lazy(() => import('../setting/regionals/regional_edit'));

const Cabang = lazy(() => import('../setting/cabang/cabang'));
const AddCabang = lazy(() => import('../setting/cabang/cabang_add'));
const EditCabang = lazy(() => import('../setting/cabang/cabang_edit'));
const DetailCabang = lazy(() => import('../setting/cabang/cabang_detail'));

const Users = lazy(() => import('../setting/users/users'));
const AddUsers = lazy(() => import('../setting/users/user_add'));
const DetailUsers = lazy(() => import('../setting/users/user_detail'));
const EditUsers = lazy(() => import('../setting/users/user_edit'));

const Akun = lazy(() => import('../setting/accounts/accounts'));
const AddAkun = lazy(() => import('../setting/accounts/account_add'));
const EditAkun = lazy(() => import('../setting/accounts/account_edit'));

const DefAkun = lazy(() => import('../setting/def_accounts/def_accounts'));
const Pajak = lazy(() => import('../setting/pajak/pajak'));

const KatItems = lazy(() => import('../setting/kat_items/kat_items'));
const AddKatItems = lazy(() => import('../setting/kat_items/kat_item_add'));
const EditKatItems = lazy(() => import('../setting/kat_items/kat_item_edit'));
const DetailKatItem = lazy(() => import('../setting/kat_items/kat_item_detail'));

const PaketItems = lazy(() => import('../setting/item_packages/item_packages'));
const AddPaketItems = lazy(() => import('../setting/item_packages/item_package_add'));
const EditPaketItems = lazy(() => import('../setting/item_packages/item_package_edit'));
const DetailPackage = lazy(() => import('../setting/item_packages/item_package_detail'));

const Items = lazy(() => import('../setting/items/items'));
const AddItems = lazy(() => import('../setting/items/items_add'));
const EditItems = lazy(() => import('../setting/items/items_edit'));
const DetailItems = lazy(() => import('../setting/items/items_detail'));

const OPakun = lazy(() => import('../setting/opening_balance/op_akun'));
const OPakunAdd = lazy(() => import('../setting/opening_balance/op_akun_add'));
const OPakunEdit = lazy(() => import('../setting/opening_balance/op_akun_edit'));
const OpDetail = lazy(() => import('../setting/opening_balance/op_akun_detail'));

const OPhutang = lazy(() => import('../setting/opening_balance/payables/payables'));
const OPaddPayable = lazy(() => import('../setting/opening_balance/payables/payable_add'));
const OPeditPayable = lazy(() => import('../setting/opening_balance/payables/payable_edit'));
const Payabledetail = lazy(() => import('../setting/opening_balance/payables/payable_detail'));

const OPpiutang = lazy(() => import('../setting/opening_balance/receivables/receivables'));
const OPaddReceivable = lazy(() => import('../setting/opening_balance/receivables/receivables_add'));
const OPeditReceivable = lazy(() => import('../setting/opening_balance/receivables/receivables_edit'));
const OPreceivableD = lazy(() => import('../setting/opening_balance/receivables/receivables_detail'));

const OPumcustomers = lazy(() => import('../setting/opening_balance/sale_advances/sale_advances'));
const OPumcustomerAdd = lazy(() => import('../setting/opening_balance/sale_advances/sale_advances_add'));
const OPumcustomerEdit = lazy(() => import('../setting/opening_balance/sale_advances/sale_advances_edit'));
const OPumcustomerDetail = lazy(() => import('../setting/opening_balance/sale_advances/sale_advances_detail'));

const OPumsupp = lazy(() => import('../setting/opening_balance/purchase_advances/purchase_advances'));
const OPumsuppAdd = lazy(() => import('../setting/opening_balance/purchase_advances/purchase_advances_add'));
const OPumsuppEdit = lazy(() => import('../setting/opening_balance/purchase_advances/purchase_advances_edit'));
const OPumsuppDetail = lazy(() => import('../setting/opening_balance/purchase_advances/purchase_advances_detail'));

const OPstock = lazy(() => import('../setting/opening_balance/stock/stocks'));
const OPstockAdd = lazy(() => import('../setting/opening_balance/stock/stocks_add'));
const OPstockEdit = lazy(() => import('../setting/opening_balance/stock/stocks_edit'));
const OPstockDetail = lazy(() => import('../setting/opening_balance/stock/stocks_detail'));

const CaraBayar = lazy(() => import('../setting/payment_methods/payment_methods'));
const AddCaraBayar = lazy(() => import('../setting/payment_methods/payment_method_add'));
const EditCaraBayar = lazy(() => import('../setting/payment_methods/payment_method_edit'));

const Discounts = lazy(() => import('../setting/item_discounts/item_discounts'));
const AddDiscount = lazy(() => import('../setting/item_discounts/item_discount_add'));
const EditDiscount = lazy(() => import('../setting/item_discounts/item_discount_edit'));

const CashKat = lazy(() => import('../setting/cash_kat/cash_kat'));
const CashKatDetail = lazy(() => import('../setting/cash_kat/cash_kat_detail'));

const Kontak = lazy(() => import('../contact/contacts'));
const AddKontak = lazy(() => import('../contact/contacts_add'));
const EditKontak = lazy(() => import('../contact/contacts_edit'));
const DetailKontak = lazy(() => import('../contact/contacts_detail'));

const Purchases = lazy(() => import('../purchases/purchases'));
const PurchaseAdd = lazy(() => import('../purchases/purchase_add'));
const PurchaseEdit = lazy(() => import('../purchases/purchase_edit'));
const PurchaseDet = lazy(() => import('../purchases/purchase_detail'));

const PurchaseReturns = lazy(() => import('../purchase_returns/purchase_returns'));
const PReturnAdd = lazy(() => import('../purchase_returns/purchase_return_add'));
const PReturnEdit = lazy(() => import('../purchase_returns/purchase_return_edit'));
const PurchaseRDet = lazy(() => import('../purchase_returns/purchase_return_detail'));

const Payment = lazy(() => import('../payments/payments'));
const PaymentAdd = lazy(() => import('../payments/payment_add'));
const PaymentEdit = lazy(() => import('../payments/payment_edit'));
const PaymentDet = lazy(() => import('../payments/payment_detail'));

const Sales = lazy(() => import('../sales/sales'));
const SaleAdd = lazy(() => import('../sales/sale_add'));
const SaleEdit = lazy(() => import('../sales/sale_edit'));
const SaleDet = lazy(() => import('../sales/sale_detail'));

const SaleReturns = lazy(() => import('../sale_returns/sale_returns'));
const SReturnAdd = lazy(() => import('../sale_returns/sale_return_add'));
const SReturnEdit = lazy(() => import('../sale_returns/sale_return_edit'));
const SaleRDet = lazy(() => import('../sale_returns/sale_return_detail'));

const Receive = lazy(() => import('../receives/receives'));
const ReceiveAdd = lazy(() => import('../receives/receive_add'));
const ReceiveEdit = lazy(() => import('../receives/receive_edit'));
const ReceiveDet = lazy(() => import('../receives/receive_detail'));

const Opname = lazy(() => import('../stock_opnames/stock_opnames'));
const OpnameAdd = lazy(() => import('../stock_opnames/stock_opname_add'));
const OpnameEdit = lazy(() => import('../stock_opnames/stock_opname_edit'));
const OpnameD = lazy(() => import('../stock_opnames/stock_opname_detail'));

const StockOut = lazy(() => import('../stock_outs/index'));
const StockOutAdd = lazy(() => import('../stock_outs/add'));
const StockOutEdit = lazy(() => import('../stock_outs/edit'));
const StockOutDetail = lazy(() => import('../stock_outs/detail'));

const Mutations = lazy(() => import('../stock_mutations/stock_mutations'));
const MutationAdd = lazy(() => import('../stock_mutations/stock_mutation_add'));
const MutationEdit = lazy(() => import('../stock_mutations/stock_mutation_edit'));
const MutasiD = lazy(() => import('../stock_mutations/stock_mutation_details'));

const BOM = lazy(() => import('../bom/bill_of_materials'));
const AddBOM = lazy(() => import('../bom/bill_of_material_add'));
const EditBOM = lazy(() => import('../bom/bill_of_material_edit'));

const Productions = lazy(() => import('../productions/productions'));
const AddProductions = lazy(() => import('../productions/production_add'));
const EditProductions = lazy(() => import('../productions/production_edit'));

const Giro = lazy(() => import('../current_accounts/current_accounts'));
const AddGiro = lazy(() => import('../current_accounts/current_account_add'));
const EditGiro = lazy(() => import('../current_accounts/current_account_edit'));
const GiroDetail = lazy(() => import('../current_accounts/current_account_detail'));

const JurnalUmum = lazy(() => import('../jurnal/jurnals'));
const JurnalAdd = lazy(() => import('../jurnal/jurnal_add'));
const JurnalEdit = lazy(() => import('../jurnal/jurnal_edit'));
const JurnalDetail = lazy(() => import('../jurnal/jurnal_detail'));

const LapKeu = lazy(() => import('../financial_reports/financial_reports'));
const LapJU = lazy(() => import('../financial_reports/jurnal_umum'));
const LapBB = lazy(() => import('../financial_reports/buku_besar'));
const LapBB_P = lazy(() => import('../financial_reports/buku_besar_p'));
const LapBB_H = lazy(() => import('../financial_reports/buku_besar_h'));
const LapBB_Pnj = lazy(() => import('../financial_reports/buku_besar_pnj'));
const LapBB_Pmb = lazy(() => import('../financial_reports/buku_besar_pmb'));
const LapNS = lazy(() => import('../financial_reports/neraca_saldo'));
const LapLR = lazy(() => import('../financial_reports/laba_rugi'));
const LapLR_P = lazy(() => import('../financial_reports/laba_rugi_p'));
const LapEK = lazy(() => import('../financial_reports/ekuitas'));
const LapPK = lazy(() => import('../financial_reports/posisi_keuangan'));
const LapAK = lazy(() => import('../financial_reports/arus_kas'));
const LapKS = lazy(() => import('../financial_reports/kartu_stok'));
const LapRP = lazy(() => import('../financial_reports/rekap_penjualan'));
const LapPIP = lazy(() => import('../financial_reports/penjualan_item'));
const LapPKT = lazy(() => import('../financial_reports/komposisi_paket'));
const LapLO = lazy(() => import('../financial_reports/lo'));
const ViewLap = lazy(() => import('../financial_reports/report_view'));


class App extends React.Component {
    
    componentDidMount() {
        $(window).bind('load resize', function() {
          correctHeight();
          detectBody();
        });
    }


    render() {
        return (
            <Router history={history}>
                 <Switch>
                  <Route  path='/login'  component={WaitingComponent(Login)} />
                  <PrivateRoute path='/' component={Nav} />
                 </Switch>
            </Router>
        );
    }

}



const Nav = (props) =>{
    return (   
        <div>
        <div id="wrapper" className="app">
        <Progress/>
        <Navigation location={props.location} history={props.history}/>
         <div id="page-wrapper" className="gray-bg">
            <TopHeader history={props.history}/>  
            <Switch>
            <PrivateRoute exact path="/" idrole={1} component={WaitingComponent(Home)} />
            
            <PrivateRoute exact path="/regional" idrole={188} component={WaitingComponent(Regional)} />
            <PrivateRoute exact path="/regional/add" idrole={191} component={WaitingComponent(AddRegional)} />
            <PrivateRoute exact path="/regional/edit/:id" idrole={192} component={WaitingComponent(EditRegional)} />
            
            <PrivateRoute exact path="/cabang" idrole={3} component={WaitingComponent(Cabang)} />
            <PrivateRoute exact path="/cabang/add"  idrole={5} component={WaitingComponent(AddCabang)} />
            <PrivateRoute exact path="/cabang/edit/:id"  idrole={6} component={WaitingComponent(EditCabang)} />
            <PrivateRoute path="/cabang/detail/:id"  idrole={8} component={WaitingComponent(DetailCabang)} />

            <PrivateRoute exact path="/users" idrole={9} component={WaitingComponent(Users)} />
            <PrivateRoute exact path="/users/add"  idrole={11} component={WaitingComponent(AddUsers)} />
            <PrivateRoute path="/users/detail/:id" idrole={14} component={WaitingComponent(DetailUsers)} />
            <PrivateRoute exact path="/users/edit/:id" idrole={12} component={WaitingComponent(EditUsers)} />

            <PrivateRoute exact path="/accounts"  idrole={15} component={WaitingComponent(Akun)} />
            <PrivateRoute exact path="/accounts/add" idrole={18} component={WaitingComponent(AddAkun)} />
            <PrivateRoute exact path="/accounts/edit/:id" idrole={19} component={WaitingComponent(EditAkun)} />

            <PrivateRoute exact path="/def_accounts" idrole={21} component={WaitingComponent(DefAkun)} />
            <PrivateRoute exact path="/pajak" idrole={23} component={WaitingComponent(Pajak)} />
            
            <PrivateRoute exact path="/kat_items" idrole={176} component={WaitingComponent(KatItems)} />
            <PrivateRoute exact path="/kat_items/add" idrole={178} component={WaitingComponent(AddKatItems)} />
            <PrivateRoute exact path="/kat_items/edit/:id" idrole={179} component={WaitingComponent(EditKatItems)} />
            <PrivateRoute exact path="/kat_items/detail/:id" idrole={181} component={WaitingComponent(DetailKatItem)} />
            
            <PrivateRoute exact path="/items" idrole={29} component={WaitingComponent(Items)} />
            <PrivateRoute exact path="/items/add" idrole={32} component={WaitingComponent(AddItems)} />
            <PrivateRoute exact path="/items/edit/:id" idrole={33} component={WaitingComponent(EditItems)} />
            <PrivateRoute path="/items/detail/:id" idrole={29} component={WaitingComponent(DetailItems)} />
            
            <PrivateRoute exact path="/paket_items" idrole={182} component={WaitingComponent(PaketItems)} />
            <PrivateRoute exact path="/paket_items/add" idrole={184} component={WaitingComponent(AddPaketItems)} />
            <PrivateRoute exact path="/paket_items/edit/:id" idrole={185} component={WaitingComponent(EditPaketItems)} />
            <PrivateRoute path="/paket_items/detail/:id" idrole={187} component={WaitingComponent(DetailPackage)} />
            
            <PrivateRoute exact path="/saldo_akun" idrole={36} component={WaitingComponent(OPakun)} />
            <PrivateRoute exact path="/saldo_akun/add" idrole={38} component={WaitingComponent(OPakunAdd)} />
            <PrivateRoute exact path="/saldo_akun/edit/:id" idrole={39} component={WaitingComponent(OPakunEdit)} />
            <PrivateRoute exact path="/saldo_akun/detail/:id" idrole={41} component={WaitingComponent(OpDetail)} />
            
            <PrivateRoute exact path="/saldo_hutang" idrole={48} component={WaitingComponent(OPhutang)} />
            <PrivateRoute exact path="/saldo_hutang/add" idrole={50} component={WaitingComponent(OPaddPayable)} />
            <PrivateRoute exact path="/saldo_hutang/edit/:id" idrole={51} component={WaitingComponent(OPeditPayable)} />
            <PrivateRoute exact path="/saldo_hutang/detail/:id" idrole={53} component={WaitingComponent(Payabledetail)} />
            
            <PrivateRoute exact path="/saldo_piutang" idrole={42} component={WaitingComponent(OPpiutang)} />
            <PrivateRoute exact path="/saldo_piutang/add" idrole={44} component={WaitingComponent(OPaddReceivable)} />
            <PrivateRoute exact path="/saldo_piutang/edit/:id" idrole={45} component={WaitingComponent(OPeditReceivable)} />
            <PrivateRoute exact path="/saldo_piutang/detail/:id" idrole={47} component={WaitingComponent(OPreceivableD)} />
            
            <PrivateRoute exact path="/saldo_umcustomers" idrole={54} component={WaitingComponent(OPumcustomers)} />
            <PrivateRoute exact path="/saldo_umcustomers/add" idrole={56} component={WaitingComponent(OPumcustomerAdd)} />
            <PrivateRoute exact path="/saldo_umcustomers/edit/:id" idrole={57} component={WaitingComponent(OPumcustomerEdit)} />
            <PrivateRoute exact path="/saldo_umcustomers/detail/:id" idrole={59} component={WaitingComponent(OPumcustomerDetail)} />
            
            <PrivateRoute exact path="/saldo_umsuppliers" idrole={60} component={WaitingComponent(OPumsupp)} />
            <PrivateRoute exact path="/saldo_umsuppliers/add" idrole={62} component={WaitingComponent(OPumsuppAdd)} />
            <PrivateRoute exact path="/saldo_umsuppliers/edit/:id" idrole={63} component={WaitingComponent(OPumsuppEdit)} />
            <PrivateRoute exact path="/saldo_umsuppliers/detail/:id" idrole={65} component={WaitingComponent(OPumsuppDetail)} />
            
            <PrivateRoute exact path="/saldo_inventory" idrole={66} component={WaitingComponent(OPstock)} />
            <PrivateRoute exact path="/saldo_inventory/add" idrole={68} component={WaitingComponent(OPstockAdd)} />
            <PrivateRoute exact path="/saldo_inventory/edit/:id" idrole={69} component={WaitingComponent(OPstockEdit)} />
            <PrivateRoute exact path="/saldo_inventory/detail/:id" idrole={71} component={WaitingComponent(OPstockDetail)} />
            
            <PrivateRoute exact path="/cara_pembayaran" idrole={215} component={WaitingComponent(CaraBayar)} />
            <PrivateRoute exact path="/cara_pembayaran/add" idrole={217} component={WaitingComponent(AddCaraBayar)} />
            <PrivateRoute exact path="/cara_pembayaran/edit/:id" idrole={218} component={WaitingComponent(EditCaraBayar)} />
            
            <PrivateRoute exact path="/discount" idrole={220} component={WaitingComponent(Discounts)} />
            <PrivateRoute exact path="/discount/add" idrole={222} component={WaitingComponent(AddDiscount)} />
            <PrivateRoute exact path="/discount/edit/:id" idrole={223} component={WaitingComponent(EditDiscount)} />
            
            <PrivateRoute exact path="/cash_kategori" idrole={72} component={WaitingComponent(CashKat)} />
            <PrivateRoute exact path="/cash_kategori/detail/:id" idrole={77} component={WaitingComponent(CashKatDetail)} />

            <PrivateRoute exact path="/kontak" idrole={78} component={WaitingComponent(Kontak)} />
            <PrivateRoute exact path="/kontak/add" idrole={81} component={WaitingComponent(AddKontak)} />
            <PrivateRoute exact path="/kontak/edit/:id" idrole={82} component={WaitingComponent(EditKontak)} />
            <PrivateRoute path="/kontak/detail/:id" idrole={84} component={WaitingComponent(DetailKontak)} />
            
            <PrivateRoute exact path="/pembelian" idrole={86} component={WaitingComponent(Purchases)} />
            <PrivateRoute exact path="/pembelian/add" idrole={88} component={WaitingComponent(PurchaseAdd)} />
            <PrivateRoute exact path="/pembelian/edit/:id" idrole={89} component={WaitingComponent(PurchaseEdit)} />
            <PrivateRoute exact path="/pembelian/detail/:id" idrole={91} component={WaitingComponent(PurchaseDet)} />
            
            <PrivateRoute exact path="/retur_pembelian" idrole={98} component={WaitingComponent(PurchaseReturns)} />
            <PrivateRoute exact path="/retur_pembelian/add" idrole={100} component={WaitingComponent(PReturnAdd)} />
            <PrivateRoute exact path="/retur_pembelian/edit/:id" idrole={101} component={WaitingComponent(PReturnEdit)} />
            <PrivateRoute exact path="/retur_pembelian/detail/:id" idrole={103} component={WaitingComponent(PurchaseRDet)} />
            
            <PrivateRoute exact path="/pembayaran" idrole={92} component={WaitingComponent(Payment)} />
            <PrivateRoute exact path="/pembayaran/add" idrole={94} component={WaitingComponent(PaymentAdd)} />
            <PrivateRoute exact path="/pembayaran/edit/:id" idrole={95} component={WaitingComponent(PaymentEdit)} />
            <PrivateRoute exact path="/pembayaran/detail/:id" idrole={97} component={WaitingComponent(PaymentDet)} />
            
            <PrivateRoute exact path="/penjualan" idrole={105} component={WaitingComponent(Sales)} />
            <PrivateRoute exact path="/penjualan/add" idrole={107} component={WaitingComponent(SaleAdd)} />
            <PrivateRoute exact path="/penjualan/edit/:id" idrole={108} component={WaitingComponent(SaleEdit)} />
            <PrivateRoute exact path="/penjualan/detail/:id" idrole={110} component={WaitingComponent(SaleDet)} />
            
            <PrivateRoute exact path="/retur_penjualan" idrole={117} component={WaitingComponent(SaleReturns)} />
            <PrivateRoute exact path="/retur_penjualan/add" idrole={119} component={WaitingComponent(SReturnAdd)} />
            <PrivateRoute exact path="/retur_penjualan/edit/:id" idrole={120} component={WaitingComponent(SReturnEdit)} />
            <PrivateRoute exact path="/retur_penjualan/detail/:id" idrole={122} component={WaitingComponent(SaleRDet)} />
            
            <PrivateRoute exact path="/penerimaan" idrole={111} component={WaitingComponent(Receive)} />
            <PrivateRoute exact path="/penerimaan/add" idrole={113} component={WaitingComponent(ReceiveAdd)} />
            <PrivateRoute exact path="/penerimaan/edit/:id" idrole={114} component={WaitingComponent(ReceiveEdit)} />
            <PrivateRoute exact path="/penerimaan/detail/:id" idrole={116} component={WaitingComponent(ReceiveDet)} />
            
            <PrivateRoute exact path="/stock_opname" idrole={124} component={WaitingComponent(Opname)} />
            <PrivateRoute exact path="/stock_opname/add" idrole={126} component={WaitingComponent(OpnameAdd)} />
            <PrivateRoute exact path="/stock_opname/edit/:id" idrole={127} component={WaitingComponent(OpnameEdit)} />
            <PrivateRoute exact path="/stock_opname/detail/:id" idrole={129} component={WaitingComponent(OpnameD)} />
            
            <PrivateRoute exact path="/stock_out" idrole={237} component={WaitingComponent(StockOut)} />
            <PrivateRoute exact path="/stock_out/add" idrole={239} component={WaitingComponent(StockOutAdd)} />
            <PrivateRoute exact path="/stock_out/edit/:id" idrole={240} component={WaitingComponent(StockOutEdit)} />
            <PrivateRoute exact path="/stock_out/detail/:id" idrole={241} component={WaitingComponent(StockOutDetail)} />
            
            <PrivateRoute exact path="/mutasi_stock" idrole={194} component={WaitingComponent(Mutations)} />
            <PrivateRoute exact path="/mutasi_stock/add" idrole={196} component={WaitingComponent(MutationAdd)} />
            <PrivateRoute exact path="/mutasi_stock/edit/:id" idrole={196} component={WaitingComponent(MutationEdit)} />
            <PrivateRoute exact path="/mutasi_stock/detail/:id" idrole={199} component={WaitingComponent(MutasiD)} />
            
            <PrivateRoute exact path="/bom" idrole={201} component={WaitingComponent(BOM)} />
            <PrivateRoute exact path="/bom/add" idrole={203} component={WaitingComponent(AddBOM)} />
            <PrivateRoute exact path="/bom/edit/:id" idrole={204} component={WaitingComponent(EditBOM)} />
            
            <PrivateRoute exact path="/produksi" idrole={207} component={WaitingComponent(Productions)} />
            <PrivateRoute exact path="/produksi/add" idrole={207} component={WaitingComponent(AddProductions)} />
            <PrivateRoute exact path="/produksi/edit/:id" idrole={211} component={WaitingComponent(EditProductions)} />
            
            <PrivateRoute exact path="/giro" idrole={150} component={WaitingComponent(Giro)} />
            <PrivateRoute exact path="/giro/add" idrole={152} component={WaitingComponent(AddGiro)} />
            <PrivateRoute exact path="/giro/edit/:id" idrole={153} component={WaitingComponent(EditGiro)} />
            <PrivateRoute path="/giro/detail/:id" idrole={155} component={WaitingComponent(GiroDetail)} />
            
            <PrivateRoute exact path="/jurnal_umum" idrole={163} component={WaitingComponent(JurnalUmum)} />
            <PrivateRoute exact path="/jurnal_umum/add" idrole={158} component={WaitingComponent(JurnalAdd)} />
            <PrivateRoute exact path="/jurnal_umum/edit/:id" idrole={159} component={WaitingComponent(JurnalEdit)} />
            <PrivateRoute exact path="/jurnal_umum/detail/:id" idrole={161} component={WaitingComponent(JurnalDetail)} />
            
            <PrivateRoute exact path="/laporan_keuangan" idrole={162} component={WaitingComponent(LapKeu)} />
            <PrivateRoute exact path="/laporan_keuangan/ju" idrole={163} component={WaitingComponent(LapJU)} />
            <PrivateRoute exact path="/laporan_keuangan/bb" idrole={164} component={WaitingComponent(LapBB)} />
            <PrivateRoute exact path="/laporan_keuangan/bb_p" idrole={165} component={WaitingComponent(LapBB_P)} />
            <PrivateRoute exact path="/laporan_keuangan/bb_h" idrole={166} component={WaitingComponent(LapBB_H)} />
            <PrivateRoute exact path="/laporan_keuangan/bb_pnj" idrole={167} component={WaitingComponent(LapBB_Pnj)} />
            <PrivateRoute exact path="/laporan_keuangan/bb_pmb" idrole={168} component={WaitingComponent(LapBB_Pmb)} />
            <PrivateRoute exact path="/laporan_keuangan/ns" idrole={169} component={WaitingComponent(LapNS)} />
            <PrivateRoute exact path="/laporan_keuangan/lr" idrole={170} component={WaitingComponent(LapLR)} />
            <PrivateRoute exact path="/laporan_keuangan/lr_p" idrole={235} component={WaitingComponent(LapLR_P)} />
            <PrivateRoute exact path="/laporan_keuangan/ek" idrole={171} component={WaitingComponent(LapEK)} />
            <PrivateRoute exact path="/laporan_keuangan/pk" idrole={172} component={WaitingComponent(LapPK)} />
            <PrivateRoute exact path="/laporan_keuangan/ak" idrole={173} component={WaitingComponent(LapAK)} />
            <PrivateRoute exact path="/laporan_keuangan/ks" idrole={174} component={WaitingComponent(LapKS)} />
            <PrivateRoute exact path="/laporan_keuangan/rp" idrole={231} component={WaitingComponent(LapRP)} />
            <PrivateRoute exact path="/laporan_keuangan/pip" idrole={232} component={WaitingComponent(LapPIP)} />
            <PrivateRoute exact path="/laporan_keuangan/pkt" idrole={233} component={WaitingComponent(LapPKT)} />
            <PrivateRoute exact path="/laporan_keuangan/lo" idrole={234} component={WaitingComponent(LapLO)} />
            <PrivateRoute exact path="/laporan_keuangan/:report_name/view" idrole={162} component={WaitingComponent(ViewLap)} />
            
            </Switch>
            <Footer/>
          </div>
        </div>
     </div>
    );
}

export { App }; 